import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Paidsearch_top from "../images/paid_search/paid_searchtop.png"
import Process__Step_Sr1 from "../images/paid_search/process1.png"
import Process__Step_Sr2 from "../images/paid_search/process2.png"
import Process__Step_Sr3 from "../images/paid_search/process3.png"
import Remarketing from "../images/paid_search/remarketing.jpg"
import Marketplace from "../images/paid_search/ssd.png"
import Ads from "../images/paid_search/ads.jpg"
import {Helmet} from "react-helmet/es/Helmet";


export default class Container extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
          <meta name="description"
                content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
          <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
          <meta name="description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta property="og:site_name" content="Cueblocks"/>
          <meta property="fb:app_id" content="289086684439915"/>
          <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
          <meta property="og:title"
                content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
          <meta property="og:description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta name="twitter:title"
                content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
          <meta name="twitter:site" content="@cueblocks"/>
          <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <article className="static_page paid_search-page">
          <section className="ser_sec_row  creative_design_page ">
            <div className="container">
              <div className="ser_sec_row_lt wdt-60 pd_right_40">
                <h2 className="heading_main">Digital Advertising</h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    The world is online and so are the brands that meet the
                    dynamic demands of its consumers globally on a daily basis.
                    But, how a brand connects with its consumers in what moment
                    is pivotal for its growth. Since Digital Advertising means
                    paying-per-interaction for the brands, our team of
                    Google-certified professionals, with over a decade of
                    experience, values each interaction and the money spent on
                    it. <br />
                    <br />
                    Covering all the touchpoints of a consumer’s purchase
                    journey, we formulate a statistic-based strategy that gets
                    your business a bang for its buck.
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt wdt-40 pd_left_40 text-center ">
                <img
                  src={Paidsearch_top}
                  alt="Cue Blocks"
                  className="img-responsive"
                />
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">Our process</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr1}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>WHO | WHERE | WHEN | HOW</h3>
                    <p>
                      In the eCommerce industry, knowing the touchpoints of your
                      consumers is of utmost importance. To get an in-depth
                      understanding of your business, we thoroughly evaluate the
                      who, where, when, and how of targeting the right audience
                      on the right platform at the right time.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>TARGET CONSUMERS WITH INTENT</h3>
                    <p>
                      With a comprehensive understanding of your brand, we begin
                      setting up the most suitable strategy by running a
                      detailed keyword search, targeting the right audience
                      based on their interests and thereby creating an engaging
                      ad that elicits a response to the Call-To-Action.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>START SLOW, TEST & GROW</h3>
                    <p>
                      Assessing the results at each stage, we constantly check
                      for consistent growth by following up with the
                      ever-changing needs of your consumers, all the while
                      keeping the focus on the purchase intent of the consumer
                      which will lead to conversions and not just traffic.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics  content-both">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <h2 className="heading_main">
                    Paid Social
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      With the evolution of technology, we tend to leave a lot of the important things on our to-do lists at the mercy of devices and software. 
                      <br />
                      <br />
                      However, a skilled individual can make all the difference when it comes to identifying and fixing any loopholes especially when the performance of 
                      your website is concerned. We have a whole team of highly-skilled developers to take care of that for you.
                    </p>
                  </div>

                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    Paid Search
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      The attention span of consumers, nowadays, is quite
                      fickle. Therefore, your brand should be able to keep up
                      with what the consumers are searching for while
                      maintaining an active and stable presence in the online
                      world. With the help of Google/ Bing Text & Shopping ads,
                      we target the touchpoints of your consumers with enticing
                      content, anchoring the highest probability of a
                      conversion. <br />
                      <br />
                      We help you create paid search campaigns from scratch or
                      pick-up your existing setup & optimize. To know more,
                      write to us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="conpetulatization ">
            <div className="container">
              <div className="all-grows-block">
                <div className="ser_sec_row_lt  wdt-40">
                  <h2 className="heading_main">
                    Marketplace listing & optimization
                    <span className="highlight-span">Retrodays/allthatgrows</span>
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      The Shopify marketplace offers a range of apps and
                      pre-built modules that can help you give your customers, a
                      user experience that they will not easily forget. The more
                      user-friendly your store, the more they will talk about it
                      in their social circles.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
                <div className="ser_sec_row_rt  wdt-60 hide-mob">
                  <img
                    src={Marketplace}
                    alt="Cue Blocks"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className=" motion-graphics fleex remarketing">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Remarketing}
                      alt="Shopify Migration"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">REMARKETING</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      To think that all clicks will convert in the first
                      interaction is quite presumptuous. Ergo, to maintain a
                      socially-active identity of your business on the internet,
                      we devise the most optimal strategy to remarket your
                      products and services through Google & Facebook which
                      allows us to review, respond, and resolve every possible
                      query that your consumers might have, post their visit to
                      your website.
                      <br />
                      <br />
                      Reach out to us to understand how remarketing can help
                      your business grow.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img fleex last_product ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_20 right">
                  <figure>
                    <img
                      src={Ads}
                      alt="Comparison Shopping Engines"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_40">
                  <h2 className="heading_main">Comparison Shopping Engines</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      An elegant, intuitive, and stand-out design that coincides
                      with your products serves as the foundation on which user
                      experience is built. We help you pick the most suitable
                      theme for your e-store that will look aesthetically
                      pleasing while offering all the required features to your
                      customers.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

        </article>
      </Layout>
    )
  }
}
